<template>
  <div class="bt-login pa-10">
    <v-form autocomplete="off">
      <v-row>
        <v-col xs="12" cols="12">
          <img
            src="/img/icons/logo.png"
            class="bt-login-image mx-auto d-flex"
          />
        </v-col>
        <v-col xs="12" cols="12" v-show="showInputUsername">
          <v-card elevation="3" class="pa-3 rounded-xl">
            <v-text-field
              color="black"
              placeholder="Masukkan NIK Anda"
              v-model="form.data.nama_user"
              autocomplete="false"
              hide-details
              flat
              solo
              dense
              prepend-inner-icon="mdi-account"
              name="nama_user"
              :type="'number'"
              inputmode="numeric"
              pattern="[0-9]*"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            />
          </v-card>
        </v-col>
        <v-col xs="12" cols="12" v-show="showInputUsername">
          <v-btn
            block
            class="purple lighten-1 white--text rounded-pill py-8 text-h6"
            type="button"
            @click="checkUsername"
            :loading="form.loading"
          >
            <template v-slot:loader>
              <span class="custom-loader" :loading="form.loading">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
            Cek NIK
          </v-btn>
        </v-col>
        <v-col xs="12" cols="12" v-show="showInputPassword">
          <v-card elevation="3" class="pa-3 rounded-xl">
            <v-text-field
              color="black"
              placeholder="Masukkan Password Anda"
              v-model="form.data.password"
              :type="showPass ? 'text' : 'password'"
              autocomplete="false"
              hide-details
              flat
              solo
              dense
              prepend-inner-icon="mdi-lock"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPass = !showPass"
            />
          </v-card>
        </v-col>
        <v-col xs="12" cols="12">
          <v-btn
            block
            class="purple lighten-1 white--text rounded-pill py-8 text-h6"
            type="button"
            @click="doLogin"
            v-show="showInputPassword"
          >
            Login
            <template v-slot:loader>
              <span class="custom-loader" :loading="form.loading">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>

<script>
import Toast from "@/components/Toast";
import { mapGetters, mapActions } from "vuex";
import services from "../services";
export default {
  name: "Login",
  components: {
    Toast,
  },
  data() {
    return {
      form: {
        data: {
          nama_user: "",
          password: "",
          fa_code: "",
          status: 0,
        },
        loading: false,
      },
      showPass: false,
      alert: {
        show: false,
        msg: "",
      },
      showInputUsername: true,
      showInputPassword: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["login"]),
    onlyNumber: function (evt) {
      const { target, key } = evt;
      const expect = target.value.toString() + key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      }
    },
    async doLogin() {
      try {
        let payload = new FormData();
        payload.append("username", this.form.data.nama_user);
        payload.append("fa_code", this.form.data.fa_code);
        payload.append("password", this.form.data.password);
        payload.append("status", this.form.data.status);

        const req = await services.authCheckPassworLogin(payload);
        const { status, msg, data } = req.data;
        if (status) {
          const { token } = data;
          data.token = token;
          this.login(data);
          this.alert = {
            show: true,
            msg: msg,
          };
          this.$router.push("/").catch(() => {});
        } else {
          this.alert = {
            show: true,
            msg: msg,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async checkUsername() {
      try {
        const payload = new FormData();
        payload.append("username", this.form.data.nama_user);
        const req = await services.authCheckUsername(payload);
        const { status, msg, data } = req.data;
        if (status) {
          this.form.data.fa_code = data.fa_code || "";
          this.form.data.status = data.status || 0;
          this.showInputPassword = true;
          this.showInputUsername = false;
        } else {
          this.alert = {
            show: true,
            msg: msg,
          };
        }
      } catch (error) {
        console.log({ error });

        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
  },
};
</script>
